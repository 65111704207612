export const red = "#f1947b";
export const redLighten1 = "#f3aa96";
export const redLighten2 = "#fbeae5";
export const redDarken1 = "#ef7c5c";
export const redDarken2 = "#d76045";
export const yellow = "#f4c463";
export const yellowLighten1 = "#f5cd5e";
export const yellowLighten2 = "#fcf5dd";
export const yellowLighten3 = "#fffcf5";
export const yellowDarken1 = "#f6bd52";
export const yellowDarken2 = "#f4ae3d";
export const blue = "#619cf8";
export const blueLighten1 = "#6ea4f8";
export const blueLighten2 = "#e1ecfd";
export const blueDarken1 = "#5393f8";
export const blueDarken2 = "#3f85f7";
export const green = "#4ea97c";
export const greenLighten1 = "#90bb93";
export const greenLighten2 = "#f2f8f5";
export const greenDarken1 = "#439569";
export const greenDarken2 = "#3a855e";
export const gray = "#929292";
export const grayLighten1 = "#b8b8b8";
export const grayLighten2 = "#ededed";
export const grayLighten3 = "#f5f5f5";
export const grayDarken1 = "#484848";
export const grayDarken2 = "#20222b";
