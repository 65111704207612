import React from "react";
import DictionaryRes from "types/res/DictionaryRes";
import { FilterValue } from "types/res/UserCustomViewRes";
import { ProcessSettingProcessRes } from "types/res/ProcessSettingRes";
import FilePropertyRes from "types/res/FilePropertyRes";
import ProcessGroupRes from "types/res/ProcessGroupRes";
import { ReactComponent as warningSvg } from "assets/images/warning.svg";
import { ReactComponent as checkSvg } from "assets/images/check.svg";
import { ReactComponent as lookSvg } from "assets/images/look.svg";
import { ReactComponent as channelIcon } from "assets/images/channel.svg";
import { ReactComponent as textIcon } from "assets/images/text.svg";
import { ReactComponent as tagIcon } from "assets/images/hashtag.svg";
import { ReactComponent as ideaIcon } from "assets/images/bulb.svg";
import { ReactComponent as membersIcon } from "assets/images/pair-users.svg";
import { ReactComponent as goodReactionSvg } from "assets/images/good.svg";
import { ReactComponent as eyeReactionSvg } from "assets/images/look.svg";
import { ReactComponent as heartReactionSvg } from "assets/images/heart.svg";
import { ReactComponent as checkReactionSvg } from "assets/images/check.svg";
import { ReactComponent as thinkReactionSvg } from "assets/images/think.svg";

export const REQUIRED = "必須の入力項目です";
export const INVALID_POSTAL_CODE =
  "123-4567（ハイフンなし可）の形式で入力してください";
export const INVALID_EMAIL = "メールアドレスの形式で入力してください";
export const INVALID_SLUG = "半角英数字で入力してください";
export const INVALID_PHONE_NUMBER =
  "電話番号の形式（ハイフンなし可）で入力してください";
export const MAX_WIDTH = "1400px";
export const HEADER_HEIGHT = "56px";
export const HEADER_HEIGHT_WITH_PADDING = "86px";
export const SIDEBAR_HEIGHT = `calc(100% - ${HEADER_HEIGHT})`;
export const YOUTUBE_LP_HEADER_HEIGHT = "80px";
export const YOUTUBE_LP_HEADER_HEIGHT_SP = "50px";

export const TODO_ICON: {
  [iconType in "warning" | "check" | "look"]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
} = {
  warning: warningSvg,
  check: checkSvg,
  look: lookSvg,
};
export const TODO_MSG = (key: string, name: string) => {
  const values: { [key: string]: string } = {
    UNREACHED_THIS_MONTH_FIX_PLAN_COUNT: "今月の目標本数に足りていません。",
    UNREACHED_NEXT_MONTH_FIX_PLAN_COUNT: "来月の目標本数に足りていません。",
    CONCERN_ABOUT_PLAN_STOCK: "確定している最終日から、一ヶ月を切りました。",
    BLANK_AT: `${name}日を入力してください。`,
    INFORM_TOMORROW: `明日、${name}日です。`,
    INFORM_TODAY: `本日、${name}日です。${name}完了後、完了チェックしてください。`,
    EXCESS: `${name}日が過ぎています。完了してください。`,
    IMCOMPLETE: `${name}を完了してください。`,
  };
  return values[key];
};
export const WEEK_DAYS = ["日", "月", "火", "水", "木", "金", "土"];
export const WEEK_DAY_JA = {
  Sun: "日",
  Mon: "月",
  Tue: "火",
  Wed: "水",
  Thu: "木",
  Fri: "金",
  Sat: "土",
} as const;
export const TIME_OPTIONS = [
  "0:00",
  "0:15",
  "0:30",
  "0:45",
  "1:00",
  "1:15",
  "1:30",
  "1:45",
  "2:00",
  "2:15",
  "2:30",
  "2:45",
  "3:00",
  "3:15",
  "3:30",
  "3:45",
  "4:00",
  "4:15",
  "4:30",
  "4:45",
  "5:00",
  "5:15",
  "5:30",
  "5:45",
  "6:00",
  "6:15",
  "6:30",
  "6:45",
  "7:00",
  "7:15",
  "7:30",
  "7:45",
  "8:00",
  "8:15",
  "8:30",
  "8:45",
  "9:00",
  "9:15",
  "9:30",
  "9:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
  "23:00",
  "23:15",
  "23:30",
  "23:45",
];

export const FONT_SIZE = {
  XXSmall: 1.0,
  XSmall: 1.2,
  Small: 1.4,
  Medium: 1.6,
  Large: 1.8,
  XLarge: 2.0,
  XXLarge: 2.2,
} as const;

export const FONT_WEIGHT = {
  Regular: 400,
  Medium: 500,
  Bold: 700,
  XBold: 800,
} as const;

// NOTE: レビュー指定時間の（詳細な）調整の際に、ドラッグでどれだけ時間を進める/戻すかの量
export const PRICISE_VIDEO_TIME_AMOUNT = 0.01;

export const VIDEO_FILE_TYPE = 0;
export const IMAGE_FILE_TYPE = 1;
export const PDF_FILE_TYPE = 2;
export const YOUTUBE_FILE_TYPE = 3;
export const VIMEO_FILE_TYPE = 4;
export const PPTX_FILE_TYPE = 5;
export const PPT_FILE_TYPE = 6;
export const DOCX_FILE_TYPE = 7;
export const DOC_FILE_TYPE = 8;
export const PSD_FILE_TYPE = 9;
export const AI_FILE_TYPE = 10;
export const TEXT_LINK_FILE_TYPE = 11;
export const OTHER_FILE_TYPE = 99;
export const COMPLETE_TYPE_MANUAL = 0;

export const ITEM_COUNT_PER_PAGE = 30;
export const REVIEW_ITEM_COUNT_PER_PAGE = 19;

const channelMultiSelect = {
  predicate: "CHANNEL_MULTI_SELECT",
  defaultValue: [] as number[],
  isDefault: true,
};
const channelMine = {
  predicate: "CHANNEL_MINE",
  defaultValue: true,
  isDefault: true,
};
const textSearch = {
  predicate: "TEXT_SEARCH",
  defaultValue: "",
  isDefault: true,
};
const tagMultiSelect = {
  predicate: "TAG_MULTI_SELECT",
  defaultValue: [] as number[],
  isDefault: true,
};
const categoryMultiSelect = {
  predicate: "CATEGORY_MULTI_SELECT",
  defaultValue: [] as number[],
  isDefault: true,
};
export const ideaSelect = {
  predicate: "IDEA_SELECT",
  defaultValue: "IDEA",
  isDefault: true,
  selections: [
    {
      name: "IDEA",
      text: "アイデア",
    },
    {
      name: "BEGIN",
      text: "実施企画",
    },
    {
      name: "REJECTED",
      text: "ボツ企画のみ",
    },
  ],
};
const userMultiSelect = {
  predicate: "USER_MULTI_SELECT",
  defaultValue: [] as number[],
  isDefault: true,
};

const userStauts = {
  predicate: "USER_STATUS",
  defaultValue: true,
  isDefault: false,
};
const completeStatus = {
  predicate: "COMPLETE_STATUS",
  defaultValue: true,
  isDefault: false,
};
const dateStatus = {
  predicate: "DATE_STATUS",
  defaultValue: true,
  isDefault: false,
};
export const relativeDate = {
  predicate: "RELATIVE_DATE",
  defaultValue: "TODAY",
  isDefault: false,
  selections: [
    {
      name: "TODAY",
      text: "今日",
    },
    {
      name: "THIS_MONTH",
      text: "今月",
    },
    {
      name: "NEXT_TWO_MONTH",
      text: "今月・来月",
    },
    {
      name: "AFTER_THIS_MONTH",
      text: "今月以降",
    },
    {
      name: "THREE_DAYS_FROM_TODAY",
      text: "今日から3日間",
    },
    {
      name: "FIVE_DAYS_FROM_TODAY",
      text: "今日から5日間",
    },
    {
      name: "TEN_DAYS_FROM_TODAY",
      text: "今日から10日間",
    },
    {
      name: "LAST_MONTH",
      text: "先月",
    },
    {
      name: "LAST_TWO_MONTH",
      text: "先月・今月",
    },
    {
      name: "THIS_WEEK",
      text: "今週",
    },
    {
      name: "NEXT_MONTH",
      text: "来月",
    },
  ],
};
const rangeSelect = {
  predicate: "RANGE_SELECT",
  defaultValue: {
    startDate: null as Date,
    endDate: null as Date,
  },
  isDefault: false,
};
export type FILTER_TYPE =
  | "channel"
  | "planName"
  | "tag"
  | "category"
  | "progress"
  | string;
export const FILTER_FORM_COLUMN_ICON = (key: FILTER_TYPE) => {
  switch (key) {
    case "channel":
      return channelIcon;
    case "planName":
      return textIcon;
    case "tag":
    case "category":
      return tagIcon;
    case "idea":
      return ideaIcon;
    default:
      return membersIcon;
  }
};
export const FILTER_FORM_COLUMN_TEXT = (
  key: FILTER_TYPE,
  dictionary: DictionaryRes,
  process: { [key: string]: ProcessSettingProcessRes }
) => {
  switch (key) {
    case "channel":
      return dictionary.channel;
    case "planName":
      return `${dictionary.plan}名`;
    case "tag":
      return "タグ";
    case "category":
      return "カテゴリー";
    case "idea":
      return "企画アイデア";
    case "progress":
      return dictionary.progressAssigner;
    default: {
      const prc = process[key];
      return prc?.name || "未定義";
    }
  }
};
export const FILTER_FORM_PREDICATE_TEXT = (key: string) => {
  switch (key) {
    case "CHANNEL_MULTI_SELECT":
      return "チャンネル選択";
    case "CHANNEL_MINE":
      return "担当チャンネル";
    case "TEXT_SEARCH":
      return "テキスト検索";
    case "TAG_MULTI_SELECT":
      return "タグ選択";
    case "CATEGORY_MULTI_SELECT":
      return "カテゴリー選択";
    case "IDEA_SELECT":
      return "ステータス";
    case "USER_MULTI_SELECT":
      return "メンバー選択";
    case "USER_STATUS":
      return "担当者入力有無";
    case "COMPLETE_STATUS":
      return "完了ステータス";
    case "DATE_STATUS":
      return "日付入力有無";
    case "RELATIVE_DATE":
      return "関連日付";
    case "RANGE_SELECT":
      return "日付選択";
    default:
      return "未定義";
  }
};
type FilterFormItem = {
  predicate: string;
  defaultValue: string | string[] | number | number[] | boolean | FilterValue;
  isDefault: boolean;
  selections?: {
    name: string;
    text: string;
  }[];
};
type FilterFormDefinition = {
  [definitionType in FILTER_TYPE]: FilterFormItem[];
};
export const generateFilterFormDefinition = (
  process: { [key: string]: ProcessSettingProcessRes },
  order: string[]
): FilterFormDefinition => {
  const definition: FilterFormDefinition = {
    channel: [channelMultiSelect, channelMine],
    planName: [textSearch],
    tag: [tagMultiSelect],
    category: [categoryMultiSelect],
    progress: [userMultiSelect, userStauts],
  };

  // NOTE: idea関連の工程だけ特別扱いする(ideaSelect)
  const ideaProcess = process["fix_idea"];
  if (ideaProcess.isDisplay) {
    definition["idea"] = [ideaSelect];
  }

  order.forEach((identifier) => {
    if (["fix_idea", "reject_idea", "begin_project"].includes(identifier))
      return;

    const prc = process[identifier];
    if (!prc.isDisplay) return;

    let value: FilterFormItem[] = [];
    if (prc.assignable) value = [...value, userMultiSelect, userStauts];
    // 9: 完了なし（変更不可）
    if (prc.completeType !== 9) value = [...value, completeStatus];
    if (prc.schedulable)
      value = [...value, dateStatus, relativeDate, rangeSelect];
    // NOTE: 有効なvalueが存在しない場合はフィルターのフォームとして返さない
    if (value.length > 0) {
      const isPresentDefaultItem = value.some((v) => v.isDefault);
      if (!isPresentDefaultItem) {
        value[0].isDefault = true;
      }
      definition[identifier] = value;
    }
  });

  return definition;
};
export type SORT_TYPE = string;
type SortFormDefinition = {
  [definitionType in SORT_TYPE]: { defaultValue: "asc" | "desc" };
};
export const generateSortFormDefinition = (
  process: { [key: string]: ProcessSettingProcessRes },
  order: string[]
): SortFormDefinition => {
  const definition: SortFormDefinition = {};
  order.forEach((identifier) => {
    const prc = process[identifier];
    if (prc.isDisplay && prc.schedulable) {
      definition[identifier] = { defaultValue: "asc" };
    }
  });
  return definition;
};
export const generateDefaultPropertyList = (
  order: string[],
  fileProperties: FilePropertyRes[],
  processGroups: ProcessGroupRes[]
): string[] => {
  return [
    "channel",
    "name",
    "tag",
    "category",
    "status",
    "progress",
    // NOTE: idea: Kanban用. ProcessSetting.processとしては存在しないidentifierである.
    "idea",
    ...(order || []),
    "overview",
    "relatedLinks",
    ...fileProperties.map((pr) => `fileProperty_${pr.id}`),
    ...processGroups.map((pg) => `processGroup_${pg.id}`),
    "videoFileFolder",
  ];
};

export const REACTION_TYPES = [
  "good",
  "eye",
  "heart",
  "check",
  "think",
] as const;
export const REACTION_ICON: {
  [iconType in typeof REACTION_TYPES[number]]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
} = {
  good: goodReactionSvg,
  eye: eyeReactionSvg,
  heart: heartReactionSvg,
  check: checkReactionSvg,
  think: thinkReactionSvg,
};
// 2: 手動完了（変更不可）, 3: 自動完了（変更不可）, 9: 完了なし（変更不可）
export const DISABLED_COMPLETE_TYPE = [2, 3, 9];
