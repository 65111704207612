import * as React from "react";
import styled from "styled-components";

const StyledImage = styled.img<{
  imageType: "ICON_SMALL" | "ICON" | "ICON_LARGE" | "ICON_XLARGE";
  height: string;
  width: string;
  radius: string;
  isPointer: boolean;
}>`
  ${(props) =>
    props.imageType === "ICON_SMALL" &&
    `
    height: 12px;
    width: 12px;
  `}
  ${(props) =>
    props.imageType === "ICON" &&
    `
    height: 16px;
    width: 16px;
  `}
  ${(props) =>
    props.imageType === "ICON_LARGE" &&
    `
    height: 18px;
    width: 18px;
  `}
  ${(props) =>
    props.imageType === "ICON_XLARGE" &&
    `
    height: 20px;
    width: 20px;
  `}
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.radius && `border-radius: ${props.radius};`}
  ${(props) => props.isPointer && "cursor: pointer"}
`;

type Props = {
  src: string | ArrayBuffer;
  alt: string;
  imageType?: "ICON_SMALL" | "ICON" | "ICON_LARGE" | "ICON_XLARGE";
  height?: string;
  width?: string;
  radius?: string;
  clickHandler?: func;
  className?: string;
  style?: any;
};
const Image: React.FC<Props> = (props: Props) => {
  return (
    <StyledImage
      src={String(props.src)}
      alt={props.alt}
      imageType={props.imageType}
      height={props.height}
      width={props.width}
      radius={props.radius}
      onClick={() => props.clickHandler && props.clickHandler()}
      className={props.className}
      style={props.style}
      isPointer={typeof props.clickHandler === "function"}
      loading="lazy"
      decoding="async"
    />
  );
};
export default Image;
