import React from "react";

type Props = {
  component: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  icontype?:
    | "ICON_SMALL"
    | "ICON"
    | "ICON_LARGE"
    | "ICON_XLARGE"
    | "ICON_XXLARGE";
  height?: string;
  width?: string;
  radius?: string;
  clickHandler?: func;
  style?: any;
  fillcolor?: string;
  strokecolor?: string;
};
const Svg: React.FC<Props> = (props: Props) => {
  const width = () => {
    if (props.width) return props.width;
    switch (props.icontype) {
      case "ICON_SMALL":
        return "12px";
      case "ICON":
        return "16px";
      case "ICON_LARGE":
        return "18px";
      case "ICON_XLARGE":
        return "20px";
      case "ICON_XXLARGE":
        return "22px";
      default:
        return "initial";
    }
  };
  const height = () => {
    if (props.height) return props.height;
    switch (props.icontype) {
      case "ICON_SMALL":
        return "12px";
      case "ICON":
        return "16px";
      case "ICON_LARGE":
        return "18px";
      case "ICON_XLARGE":
        return "20px";
      case "ICON_XXLARGE":
        return "22px";
      default:
        return "initial";
    }
  };
  return (
    <props.component
      radius={props.radius}
      onClick={() => props.clickHandler && props.clickHandler()}
      style={{
        ...props.style,
        height: height(),
        width: width(),
        stroke: props.strokecolor,
        fill: props.fillcolor,
        minHeight: height(),
        minWidth: width(),
      }}
    />
  );
};
export default Svg;
